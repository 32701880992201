const Web3 = require("web3");

class Web3Helper {
    constructor(abi = null, contractAddress = null) {
        if (window.ethereum) {
            this.isLink = true;
            this.web3 = new Web3(window.web3.currentProvider);

            if (abi != null || contractAddress != null) {
                this.abi = JSON.parse(abi);
                this.contractAddress = contractAddress;
                this.contract = new this.web3.eth.Contract(this.abi, this.contractAddress);
            }
        } else {
            this.isLink = false;
            alert("请使用Dapp浏览器或连接MetaMask.");
        }
    }

    toWei(val, decimals) {
        return this.web3.utils.toWei(val.toString(),'ether');
    }

    fromWei(val, decimals) {
        return this.web3.utils.fromWei(val,'ether');
    }

    getSign() {
        let that = this;
        if (!that.isLink) { alert("请使用Dapp浏览器或连接MetaMask."); return; }
        return new Promise(function (resolve, reject) {
            window.ethereum.enable().then((address) => {
                window.ethereum.request({
                    method: 'personal_sign',
                    params: [address[0], that.web3.utils.sha3('20220418dapp')]
                }).then((signStr) => {
                    resolve(signStr);
                }, (err) => {
                    reject(err);
                });
            }, (err) => {
                reject(err);
            });
        });
    }

    //获取当前Dapp钱包地址
    getAddress() {
        if (!this.isLink) { alert("请使用Dapp浏览器或连接MetaMask."); return; }
        return new Promise(function (resolve, reject) {
            window.ethereum.enable().then((res) => {
                //console.log("当前钱包地址：" + res[0]);
                resolve(res[0]);
            }, (err) => {
                reject(err);
            });
        });
    }

    //获取币池价格
    getPrice() {
        let that = this;
        if (!that.isLink) { alert("请使用Dapp浏览器或连接MetaMask."); return; }
        return new Promise(function (resolve, reject) {
            that.contract.methods.getPrice().call().then((res) => {
                resolve(res);
            }, (err) => {
                reject(err);
            })
        });
    }

    //查询合约余额
    contractBalanceOf(address) {
        let that = this;
        if (!that.isLink) { alert("请使用Dapp浏览器或连接MetaMask."); return; }
        return new Promise(function (resolve, reject) {
            that.contract.methods.balanceOf(address).call().then((res) => {
                resolve(res);
            }, (err) => {
                reject(err);
            })
        });
    }

    //获取已授权金额
    contractAllowance(owner ,spender) {
        let that = this
        if (!that.isLink) { alert("请使用Dapp浏览器或连接MetaMask."); return; }
        return new Promise(function (resolve, reject) {
            that.contract.methods.allowance(owner, spender).call().then((res) => {
                resolve(res);
            }, (err) => {
                reject(err);
            })
        });      
    }

    exactInputSingle(from, tokenIn, tokenOut, fee, recipient, amountIn, amountOutMinimum) {
        let that = this;
        if (!that.isLink) { alert("请使用Dapp浏览器或连接MetaMask."); return; }
        return new Promise(function (resolve, reject) {

            let exactInputSingleParams =  {
                tokenIn:tokenIn,
                tokenOut:tokenOut,
                fee:fee,
                recipient:recipient,
                amountIn:amountIn,
                amountOutMinimum:amountOutMinimum,
                sqrtPriceLimitX96:0
            };

            //构造合约交易数据
            var transfer = that.contract.methods.exactInputSingle(exactInputSingleParams);
            //交易数据转换成data
            var encodedABI = transfer.encodeABI();

            //创建交易
            var tx = {
                from: from,
                to: that.contractAddress,
                data: encodedABI
            }

            //预估gas
            that.web3.eth.estimateGas(tx).then((gas) => {
                tx.gas = gas;
                that.web3.eth.getGasPrice().then((price) => {
                    tx.gasPrice = price;
                    // 广播签名交易
                    that.web3.eth.sendTransaction(tx).then((res) => {
                        resolve(res.transactionHash);
                    }, (err) => {
                        reject(err);
                    });
                });
            });
        });
    }

    contractSwapExactTokensForTokensSupportingFeeOnTransferTokens(from, amountIn, amountOutMin, paths, to, deadline) {
        let that = this;
        if (!that.isLink) { alert("请使用Dapp浏览器或连接MetaMask."); return; }
        return new Promise(function (resolve, reject) {
            //构造合约交易数据
            var transfer = that.contract.methods.swapExactTokensForTokensSupportingFeeOnTransferTokens(amountIn, amountOutMin, paths, to, deadline);
            //交易数据转换成data
            var encodedABI = transfer.encodeABI();

            //创建交易
            var tx = {
                from: from,
                to: that.contractAddress,
                data: encodedABI
            }

            //预估gas
            that.web3.eth.estimateGas(tx).then((gas) => {
                tx.gas = gas;
                that.web3.eth.getGasPrice().then((price) => {
                    tx.gasPrice = price;
                    // 广播签名交易
                    that.web3.eth.sendTransaction(tx).then((res) => {
                        resolve(res.transactionHash);
                    }, (err) => {
                        reject(err);
                    });
                });
            });
        });
    }

    //购买机器人
    contractBuyBot(from, botId, amount) {
        let that = this;
        if (!that.isLink) { alert("请使用Dapp浏览器或连接MetaMask."); return; }
        return new Promise(function (resolve, reject) {

            //构造合约交易数据
            var transfer = that.contract.methods.buyBot(botId, amount);
            //交易数据转换成data
            var encodedABI = transfer.encodeABI();

            //创建交易
            var tx = {
                from: from,
                to: that.contractAddress,
                data: encodedABI
            }

            //预估gas
            that.web3.eth.estimateGas(tx).then((gas) => {
                tx.gas = gas;
                that.web3.eth.getGasPrice().then((price) => {
                    tx.gasPrice = price;
                    // 广播签名交易
                    that.web3.eth.sendTransaction(tx).then((res) => {
                        resolve(res.transactionHash);
                    }, (err) => {
                        reject(err);
                    });
                });
            });
        });
    }

    contractUsdeExchangeEds(from, amount) {
        let that = this;
        if (!that.isLink) { alert("请使用Dapp浏览器或连接MetaMask."); return; }
        return new Promise(function (resolve, reject) {

            //构造合约交易数据
            var transfer = that.contract.methods.usdeExchangeEds(amount);
            //交易数据转换成data
            var encodedABI = transfer.encodeABI();

            //创建交易
            var tx = {
                from: from,
                to: that.contractAddress,
                data: encodedABI
            }

             //预估gas
             that.web3.eth.estimateGas(tx).then((gas) => {
                tx.gas = gas;
                that.web3.eth.getGasPrice().then((price) => {
                    tx.gasPrice = price;
                    // 广播签名交易
                    that.web3.eth.sendTransaction(tx).then((res) => {
                        resolve(res.transactionHash);
                    }, (err) => {
                        reject(err);
                    });
                });
            });
        });
    }

    contractEdsExchangeUsde(from, amount) {
        let that = this;
        if (!that.isLink) { alert("请使用Dapp浏览器或连接MetaMask."); return; }
        return new Promise(function (resolve, reject) {

            //构造合约交易数据
            var transfer = that.contract.methods.edsExchangeUsde(amount);
            //交易数据转换成data
            var encodedABI = transfer.encodeABI();

            //创建交易
            var tx = {
                from: from,
                to: that.contractAddress,
                data: encodedABI
            }

             //预估gas
             that.web3.eth.estimateGas(tx).then((gas) => {
                tx.gas = gas;
                that.web3.eth.getGasPrice().then((price) => {
                    tx.gasPrice = price;
                    // 广播签名交易
                    that.web3.eth.sendTransaction(tx).then((res) => {
                        resolve(res.transactionHash);
                    }, (err) => {
                        reject(err);
                    });
                });
            });
        });
    }

    //执行授权
    contractApprove(from, spender, value) {
        let that = this;
        if (!that.isLink) { alert("请使用Dapp浏览器或连接MetaMask."); return; }
        return new Promise(function (resolve, reject) {

            //构造合约交易数据
            var transfer = that.contract.methods.approve(spender, value);
            //交易数据转换成data
            var encodedABI = transfer.encodeABI();

            //创建交易
            var tx = {
                from: from,
                to: that.contractAddress,
                data: encodedABI
            }

             //预估gas
             that.web3.eth.estimateGas(tx).then((gas) => {
                tx.gas = gas;
                that.web3.eth.getGasPrice().then((price) => {
                    tx.gasPrice = price;
                    // 广播签名交易
                    that.web3.eth.sendTransaction(tx).then((res) => {
                        resolve(res.transactionHash);
                    }, (err) => {
                        reject(err);
                    });
                });
            });
        });
    }

    //执行合约转账交易
    contractTransfer(from, recipient, amount) {
        let that = this;
        if (!that.isLink) { alert("请使用Dapp浏览器或连接MetaMask."); return; }
        return new Promise(function (resolve, reject) {

            //构造合约交易数据
            var transfer = that.contract.methods.transfer(recipient, amount);
            //交易数据转换成data
            var encodedABI = transfer.encodeABI();

            //创建交易
            var tx = {
                from: from,
                to: that.contractAddress,
                data: encodedABI
            }

             //预估gas
             that.web3.eth.estimateGas(tx).then((gas) => {
                tx.gas = gas;
                that.web3.eth.getGasPrice().then((price) => {
                    tx.gasPrice = price;
                    // 广播签名交易
                    that.web3.eth.sendTransaction(tx).then((res) => {
                        resolve(res.transactionHash);
                    }, (err) => {
                        reject(err);
                    });
                });
            });
        });
    }

    //执行合约销毁(如果有的话)
    contractBurn(from, amount) {
        let that = this;
        if (!that.isLink) { alert("请使用Dapp浏览器或连接MetaMask."); return; }
        return new Promise(function (resolve, reject) {
            //构造合约交易数据
            var transfer = that.contract.methods.burn(amount);
            //交易数据转换成data
            var encodedABI = transfer.encodeABI();

            //创建交易
            var tx = {
                from: from,
                to: that.contractAddress,
                data: encodedABI
            }

             //预估gas
             that.web3.eth.estimateGas(tx).then((gas) => {
                tx.gas = gas;
                that.web3.eth.getGasPrice().then((price) => {
                    tx.gasPrice = price;
                    // 广播签名交易
                    that.web3.eth.sendTransaction(tx).then((res) => {
                        resolve(res.transactionHash);
                    }, (err) => {
                        reject(err);
                    });
                });
            });
        });
    }

    //获取交易详情
    getTransactionReceipt(txid) {
        let that = this;
        if (!that.isLink) { alert("请使用Dapp浏览器或连接MetaMask."); return; }
        return new Promise(function (resolve, reject) {
            that.web3.eth.getTransactionReceipt(txid).then((transactionReceipt) => {
                resolve(transactionReceipt);
            }, (err) => {
                reject(err);
            });
        });
    }
}

module.exports = Web3Helper;